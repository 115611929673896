import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  CssBaseline,
  ThemeProvider,
} from "@material-ui/core"
import { FormatQuoteSharp, ExpandMoreSharp } from "@material-ui/icons"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import CoreValues from "../components/CoreValues"
import Layout from "../components/Layout"
import Partners from "../components/Partners"
import customTheme from "../gatsby-theme-material-ui-top-layout/customTheme"

const query = graphql`
  {
    allFile(
      filter: { relativePath: { in: ["ceo.jpg", "aboutBackground.jpg"] } }
      sort: { fields: relativePath, order: ASC }
    ) {
      nodes {
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  sectionSeperator: {
    marginBottom: "8rem",
  },
  sectionSeperatorShadowed: {
    borderRadius: "100%",
    margin: "6rem auto 8rem",
    boxShadow: "0 14px 0px -6px #660000",
    width: "40%",
    height: "1.4rem",
  },
  aboutTitles: {
    fontSize: "3.2rem",
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
  aboutBodyText: {
    textAlign: "center",
    fontSize: "1.8rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  whoAreWe: {
    textAlign: "center",
  },
  qouteContainer: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  qoute: {
    fontSize: "1.8rem",
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  qouteMark: {
    "& > svg": {
      transform: "rotate(180deg)",
    },
  },
  qouteAtStart: {
    textAlign: "right",
    "& > svg": {
      width: "6rem",
      height: "6rem",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  qouteAtEnd: {
    alignSelf: "flex-end",
    marginTop: "-35rem",
    marginLeft: "-8rem",
    left: 0,
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      marginTop: "0rem",
      marginLeft: "0rem",
      alignSelf: "center",
    },
    "& > svg": {
      opacity: "10%",
      width: "50rem",
      height: "50rem",
      [theme.breakpoints.down("sm")]: {
        width: "25rem",
        height: "20rem",
        marginTop: "-20rem",
        marginLeft: "0rem",
      },
    },
  },
  ceoImageContainer: {
    padding: ".5rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "40rem",
      margin: "0 auto",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "20rem",
      fontSize: "1.2rem",
      boxShadow: "0 8px 6px -6px #000",
    },
  },
  ceoAbout: {
    height: "13rem",
    backgroundColor: "rgba(195, 0, 0, .5)",
    backdropFilter: "blur(3px)",
    marginTop: "-19rem",
    textAlign: "center",
    fontSize: "1.2rem",
    color: "white",
    padding: "5px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      backdropFilter: "blur(15px)",
      marginTop: "-7rem",
      height: "19rem",
      backgroundColor: "rgba(195, 0, 0, .8)",
      fontSize: "1.6rem",
    },
  },
  accordionTitle: {
    fontSize: "2.4rem",
    fontWeight: "500",
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem",
    },
  },
  AccordionDetails: {
    textAlign: "right",
    fontSize: "1.8rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  littleRedSquare: {
    backgroundColor: "rgba(195, 0, 0, 1)",
    width: "1.5rem",
    height: "1.5rem",
    display: "inline-block",
    marginLeft: "1rem",
    borderRadius: "3px",
  },
  button: {
    fontSize: "1.8rem",
    margin: "4rem auto 0",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
    "&:hover": {
      color: "#fff",
    },
  },
}))

const About = () => {
  const classes = useStyles()
  const matches600 = useMediaQuery("(max-width: 600px)")
  const pageMeta = {
    title: "حولنا",
    description: "حول شركة التميز للاستشارات وأمن المعلومات",
    keywords: [
      "عقيد صلاح الشتيوي",
      "التميز",
      "أمن معلومات",
      "أمن معلومات ليبيا",
      "استشارات أمنية ليبيا",
      "حماية معلومات ليبيا",
      "iso",
      "pcidss",
    ],
  }
  const data = useStaticQuery(query)
  const heroBackgroundImage = getImage(
    data.allFile.nodes[0].childrenImageSharp[0]
  )
  const ceoImage = getImage(data.allFile.nodes[1].childrenImageSharp[0])
  const aboutText = {
    title: "رؤيتنا",
    body:
      "فيما يخص زبائننا فإن رؤيتنا هي توفير الشريك المحلي الذي يقدم لهم الحلول المجدية في مجال أمن المعلومات وجمع أهم الخبرات في ليبيا تحت جسم واحد نسعى ليكون المظلة التي توفر المنتجات، الخدمات والأفراد المؤهلين في نفس الوقت لتغطية جزء من حاجة السوق الليبي. أما فيما يخص كل الأطراف الأخرى الفاعلة في سوق الاتصالات وتقنية المعلومات فإننا اعتمدنا سياسة الباب المفتوح لكل الراغبين في عقد اتفاقيات التعاون مع شركة التميز بأي شكل من أشكال التعاون الذي يرونها مناسبة ليتم ضبطها لاحقا بالشكل التنظيمي والقانوني الذي سيحفظ حقوق زبائن الشركة الراغبين في الاستفادة من خدماتها.",
  }

  return (
    <Layout
      pageMeta={pageMeta}
      background={heroBackgroundImage}
      aboutText={aboutText}
    >
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <div className={classes.sectionSeperator} />
        <Grid container direction="column">
          {/* Whoe are we */}
          <Grid item container justify="center" className={classes.whoAreWe}>
            <Grid item>
              <Typography className={classes.aboutTitles} paragraph>
                من نحن
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.aboutBodyText}>
                شركة التميز للاستشارات وأمن المعلومات هي شركة ليبية ذات مسؤولية
                محدودة ناشئة فتحت أبوابها سنة 2020 كنتاج لخبرة 25 سنة لمؤسسها
                أ.م صلاح الدين علي محمد الشتيوي العقيد المتقاعد من القوات
                البحرية الليبية في مجال الاتصالات وأمن المعلومات.
                <br />
                كانت الفكرة الأساسية وراء إنشاء الشركة هو العمل على سد جزء من
                حاجة السوق الليبي في مجال أمن المعلومات بما في ذلك مساعدة الجهات
                العامة والخاصة للامتثال لمعايير أمن وحماية المعلومات الدولية وأي
                سياسات محلية أخرى ذات علاقة بأمن المعلومات من خلال خبراتها
                المحلية أو من خلال التعاون مع الشركات العربية والدولية المتميزة
                في المجال ولتمثل دور مكتب إدارة مشروعات محلي متواجد باستمرار مع
                الزبون المحلي ومساعدته خطوة بخطوة نحو نيل شهادة الالتزام الدولية
                وبأقل التكاليف.
                <br /> المقر الرئيسي للشركة بمدينة طرابلس وتحمل ترخيصا من وزراة
                الاقتصاد والصناعة الليبية لمزاولة نشاط الاستشارات وأمن المعلومات
                بترخيص رقم 01041961254 وتم قيدها بغرفة التجارة والصناعة والزراعة
                طرابلس.
              </Typography>
            </Grid>
            <Grid item className={classes.sectionSeperatorShadowed} />
          </Grid>

          {/* About ceo */}
          <Grid item container direction="column">
            <Grid
              item
              container
              alignContent="center"
              spacing={2}
              style={{ paddingTop: !matches600 ? "8rem" : "2rem" }}
            >
              <Grid item md={8}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography className={classes.aboutTitles}>
                      أ.م.صلاح الدين الشتيوي
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    className={classes.qouteContainer}
                  >
                    <Grid
                      item
                      className={`${classes.qouteMark} ${classes.qouteAtStart}`}
                    >
                      <FormatQuoteSharp color="primary" />
                    </Grid>
                    <Typography className={classes.qoute}>
                      لقد تعلمت طيلة مسيرة عملي بين القطاعين العام والخاص
                      العسكري والمدني وتنفيذي لعشرات المشاريع التي لها علاقة
                      بالاتصالات وأمن المعلومات أو تلك المشاريع التدريبية
                      القتالية مع القوات البحرية لحلف الناتو منذ العام 2003 كجزء
                      من العديد من الفرق أن أهم عنصر يمكننا من خلاله الحكم على
                      نجاح أو فشل المشاريع هو الإدارة ووضوح سير العمليات وستكون
                      كل الأعمال الفنية وكل وسائل التقنية الأخرى وسائل مساعدة
                      للوصول لذلك النجاح. في عملنا من أجل حماية المعلومات سنعمل
                      بالدرجة الأولى على فهم المشاكل ودراستها وتحليلها وتحليل
                      آثارها وفهم كل تشعباتها ومن ثم وضع كل الحلول المتوفرة
                      والمناسبة لذلك الغرض ولتفسير كل ذلك فنحن سنمضي الوقت
                      الأطول في فهم وتفسيرالتهديدات ونقاط الضعف والمخاطر الناتجة
                      عنها.
                    </Typography>
                    <Typography className={classes.qoute}>
                      إن ما يدفعنا لخوض هذه المغامرة هو الشغف وهذا ما سنقدمه
                      لزبائننا من خلال التصميم المستمر لحل كل مشاكلهم.
                    </Typography>
                    <Grid
                      item
                      className={`${classes.qouteMark} ${classes.qouteAtEnd}`}
                    >
                      <FormatQuoteSharp color="primary" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                justify="center"
                md={4}
                className={classes.ceoImageContainer}
              >
                <Grid item container direction="column">
                  <GatsbyImage
                    image={ceoImage}
                    alt="ceo Salah"
                    style={{ boxShadow: "0 8px 6px -6px #000" }}
                  />
                  <div className={classes.ceoAbout}>
                    <Typography>
                      مشغل عام نظم اتصالات
                      <br />
                      ماجستير أمن الاتصالات وتقنية المعلومات
                      <br />
                      ضابط أمن نظم معلومات معتمد
                      <br />
                      خبير حماية نظم الدفع الالكتروني معتمد
                      <br />
                      كبير مسؤولي تنفيذ نظام إدارة أمن المعلومات معتمد
                      <br />
                      خبير اختبار اختراقات معتمد
                      <br />
                      مختص أمن شبكات فورتينت المستوى الثاالث
                      <br />
                      خبير امن شبكات معتمد
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.sectionSeperatorShadowed} />
          </Grid>

          {/* Partners */}
          <Grid item container direction="column">
            <Grid item container direction="column">
              <Grid item>
                <Typography className={classes.aboutTitles} paragraph>
                  شركاء التميز
                </Typography>
              </Grid>
              <Grid item>
                <Partners />
              </Grid>
            </Grid>
            <Grid item className={classes.sectionSeperatorShadowed} />
          </Grid>

          {/* Accordion data */}
          <Grid item container direction="column">
            <Grid item>
              <Grid container direction="column">
                {/* Item 1 */}
                <Grid item>
                  <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreSharp />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container alignItems="center">
                        <Typography className={classes.accordionTitle}>
                          <span className={classes.littleRedSquare} />
                          كما تدعم شركة التميز الأهداف العالمية السبعة عشر
                          للتنمية المستدامة بكل الوسائل المادية والمعنوية لاجل
                          ليبيا وأهلها
                        </Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className={classes.AccordionDetails}>
                        <strong>الهدف 1</strong> القضاء على الفقر
                        <br />
                        <strong>الهدف 2</strong> القضاء على الجوع
                        <br />
                        <strong>الهدف 3</strong> الصحة الجيدة والرفاه
                        <br />
                        <strong>الهدف 4</strong> التعليم الجيد
                        <br />
                        <strong>الهدف 5</strong> المساواة بين الجنسين
                        <br />
                        <strong>الهدف 6</strong> المياه النظيفة والنظافة الصحية
                        <br />
                        <strong>الهدف 7</strong> طافة نظيفة وبأسعار معقولة
                        <br />
                        <strong>الهدف 8</strong> العمل اللائق ونمو الاقتصاد
                        <br />
                        <strong>الهدف 9</strong> الصناعة والابتكار والهياكل
                        الأساسية
                        <br />
                        <strong>الهدف 10</strong> الحد من أوجه عدم المساواة
                        <br />
                        <strong>الهدف 11</strong> مدن ومجتمعات مستدامة
                        <br />
                        <strong>الهدف 12</strong> الاستهلاك والإنتاج المسؤولان
                        <br />
                        <strong>الهدف 13</strong> العمل المناخي
                        <br />
                        <strong>الهدف 14</strong> الحياة تحت الماء
                        <br />
                        <strong>الهدف 15</strong> الحياة في البر
                        <br />
                        <strong>الهدف 16</strong> السلام والعدل والمؤسسات القوية
                        <br />
                        <strong>الهدف 17</strong> عقد الشراكات لتحقيق الأهداف
                        <br />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                {/* Item 2 */}
                <Grid item>
                  <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreSharp />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container alignItems="center">
                        <Typography className={classes.accordionTitle}>
                          <span className={classes.littleRedSquare} />
                          معيار نظام إدارة أمن المعلومات – أيزو27001
                        </Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className={classes.AccordionDetails}>
                        المعيار أيزو 27001 هو معيار دولي نشر بواسطة المنظمة
                        الدولية للمعايير (ISO)، وهو معيار لوصف كيفية إدارة أمن
                        المعلومات بالمؤسسة. آخر تنقيح لهذا المعيار نشر سنة 2013،
                        والمعنون بـ ISO/IEC 27001:2013 . النسخة الاولى من هذا
                        المعيار نشرت بالعام 2005، حيث طورت عن المعيار البريطاني
                        BS 7799-2.
                        <br />
                        قوة المعيار تتمثل في تكيفه بحيث يمكن تطبيقه على جميع
                        انواع المؤسسات الربحية والغير ربحية، الخاصة أو المملوكة
                        للدولة، المؤسسات الصغيرة والكبيرة. وقد كتب هذا المعيار
                        بواسطة أفضل الخبراء الدوليين بمجال أمن المعلومات مزودا
                        بمنهجية لتطبيق نظم إدارة أمن المعلومات بالمؤسسة.كما يمكن
                        للمؤسسات أن تنال شهادة خاصة بتنفيذ المعيار (الالتزام) من
                        خلال تدقيق طرف خارجي على صحة تنفيذ الالتزام بمتطلبات
                        المعيار ما يعني ان جسم مستقل لمنح الشهادات يقر ان
                        المؤسسة نفذت متطلبات إدارة أمن المعلومات بما يتلائم وهذا
                        المعيار.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                {/* Item 3 */}
                <Grid item>
                  <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreSharp />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container alignItems="center">
                        <Typography className={classes.accordionTitle}>
                          <span className={classes.littleRedSquare} />
                          معيار أمن بيانات صناعة بطاقة الدفع الإئتمانية - PCI
                          DSS
                        </Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className={classes.AccordionDetails}>
                        معيار أمن بيانات صناعة بطاقة الدفع الإئتمانية (PCI DSS)
                        هو معيار صمم لدعم وتعزيز حماية بيانات حاملي البطاقات
                        الإئتمانية وضمان الالتزام الدولي بمقياس موحد لتقييم ذلك.
                        <br />
                        يعمل الالتزام بالمعيار على تزويد المؤسسات المالية، مقدمي
                        الخدمات وجميع الأطراف المشتركة في التعامل مع بيانات حامل
                        البطاقة بداية من نقاط البيع، التاجر، العملاء الماليين،
                        المصدرين، مقدمي الخدمة والمصرف المشتري بالمبادئ الاساسية
                        للمتطلبات التشغيلية والتقنية المصممة لحماية بيانات
                        الحسابات الخاصة بحاملي البطاقات.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>
            <Grid item align="center">
              <Button
                component={Link}
                to="/services"
                color="primary"
                variant="contained"
                className={classes.button}
              >
                خدماتنا
              </Button>
            </Grid>
            <Grid item className={classes.sectionSeperatorShadowed} />
          </Grid>

          {/* Call to action */}
          <Grid
            item
            container
            direction="column"
            justify="center"
            align="center"
            spacing={2}
          >
            <Grid item>
              <Typography className={classes.aboutTitles} paragraph>
                كيف يمكننا تأمينك؟
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.aboutBodyText} paragraph>
                نعمل على تأمين بياناتك بمجموعة من الإجراءات والعمليات الاستباقية
                لفهم المخاطر والتهديدات وتوفير التدريب والمعرفة اللازمة وتجهيز
                فرق قادرة على مراقبة واصطياد التهديدات بخبرة واسعة تفوق 25 عاما
                وتجارب متعددة في التعامل مع التهديدات الأمنية
              </Typography>
            </Grid>
            <Grid item>
              <CoreValues />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/contact"
                className={classes.button}
              >
                تواصل معنا
              </Button>
            </Grid>
            <Grid item className={classes.sectionSeperator} />
          </Grid>
        </Grid>
      </ThemeProvider>
    </Layout>
  )
}

export default About

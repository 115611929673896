import { Grid, makeStyles } from "@material-ui/core"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const query = graphql`
  {
    allFile(
      filter: {
        relativePath: {
          in: [
            "partner-1.png"
            "partner-2.png"
            "partner-3.png"
            "partner-5.png"
          ]
        }
      }
    ) {
      nodes {
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  partnerImage: {
    width: "20rem",
    height: "100%",
  },
}))

const Partners = () => {
  const classes = useStyles()
  const data = useStaticQuery(query)
  let partnersArray = data.allFile.nodes.map(partner =>
    getImage(partner.childrenImageSharp[0])
  )
  // let arrayLength = partnersArray.length

  return (
    <Grid
      container
      direction="row"
      wrap="wrap"
      justify="space-evenly"
      alignItems="center"
      spacing={4}
    >
      {partnersArray.map((partner, index) => {
        return (
          <Grid item key={`${partner} ${index}`}>
            <GatsbyImage
              image={partner}
              className={classes.partnerImage}
              alt="partner logo"
              key={`${partner} ${index}`}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Partners
